import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PlainHeader from "../Components/PlainHeader";
import image1 from "../Assets/svgexport-2.svg";
import image2 from "../Assets/svgexport-3.svg";
import image3 from "../Assets/svgexport-4.svg";
import Man1 from "../Assets/Man-1.svg";
import Man2 from "../Assets/Man-2.svg";
import aboutus_1 from "../Assets/aboutus-img-1.svg";
import aboutus_2 from "../Assets/aboutus-img-2.svg";
import aboutus_3 from "../Assets/aboutus-img-3.svg";
import aboutus_4 from "../Assets/aboutus-img-4.svg";
import { getAllUsersData, baseURL } from "../api/index"
import Box from "../Assets/box.svg";
import 'animate.css';
import "../Pages/Team.css";
import { LinkedInIcon } from "../Components/Icons";
import firestore from "../config/firebase";
import FooterComp from "../Components/Footer";
import { toast } from "react-toastify";
import axios from "axios";
import User from "../Assets/user.svg"
import TeamsPage from "./TeamsPage";
import baIcon from '../Assets/ba-icon.svg';



const Product = () => {
    const [team, setTeam] = useState([]);

    const getImage = async (key) => {
        try {
            let { data } = await axios.get(
                // `${BASE_URL}/common/view?key=${key}`,
                key,
                {
                    responseType: "blob",
                    headers: { application: "EMBIFI-WEBSITE" },
                    withCredentials: true,
                }
            );
            return new Promise((resolve, _) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(data);
            });
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            const { data } = await getAllUsersData();
            console.log(data?.data)
            let arr3 = data?.data;
            for (let i = 0; i < arr3.length; i++) {
                if (arr3[i].key) {
                    let key_temp = `${baseURL}/embifi-website/get-blog-image?key=${arr3[i]?.key}`;
                    let b64 = await getImage(key_temp);
                    arr3[i].image = b64;
                }
            }
            console.log(arr3)
            setTeam(arr3)
            // const snapshot = await firestore.collection("team").get();
            // setTeam(
            //     snapshot.docs.map((doc) => {
            //         const data = doc.data();
            //         data["id"] = doc.id;
            //         return data;
            //     })
            // );
        };
        fetchData();
    }, []);

    return (
        <>
            <PlainHeader />
            <div className="privacy-head-div">
                <Container gap={3}>
                    <p className="privacy-heading-about-us">Product</p>
                    <br />
                </Container>
            </div>
            <Container className="mt-4">
                <Row
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "800",
                    }}
                >
                    <Col
                        style={{ display: "flex", justifyContent: "flex-start" }}
                        md={6}
                        lg={6}
                        sm={6}
                        xs={6}
                    >
                        <ol className="product-list">
                            <li><div id="tick-mark"/>Avail secured vehicle loans from Embifi's NBFC and banking partners.</li>
                            <br />
                            <li><div id="tick-mark"/>Loan starting from Rs. 50,000 to Rs. 4,00,000 as per your requirement</li>
                            <br />
                            <li><div id="tick-mark"/>Simple pricing with interest rates varying from 15% to 18%</li>
                            <br />
                            <li><div id="tick-mark"/>Flexible tenure ranging from 9 months to 36 months</li>
                        </ol>
                    </Col>
                    <Col
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        md={6}
                        lg={6}
                        sm={6}
                        xs={6}
                    >
                        <img src={baIcon} style={{width:'80%'}} />
                    </Col>
                </Row>

            </Container>

            <div style={{ marginTop: '10em' }} />

            <FooterComp />
        </>
    );
};

export default Product;
