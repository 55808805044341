import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PlainHeader from "../Components/PlainHeader";
import { getAllUsersData, baseURL } from "../api/index"
import 'animate.css';
import "../Pages/Team.css";
import FooterComp from "../Components/Footer";
import axios from "axios";



export default function GrievanceRedressalPolicy() {
    const [team, setTeam] = useState([]);

    const getImage = async (key) => {
        try {
            let { data } = await axios.get(
                // `${BASE_URL}/common/view?key=${key}`,
                key,
                {
                    responseType: "blob",
                    headers: { application: "EMBIFI-WEBSITE" },
                    withCredentials: true,
                }
            );
            return new Promise((resolve, _) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(data);
            });
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            const { data } = await getAllUsersData();
            console.log(data?.data)
            let arr3 = data?.data;
            for (let i = 0; i < arr3.length; i++) {
                if (arr3[i].key) {
                    let key_temp = `${baseURL}/embifi-website/get-blog-image?key=${arr3[i]?.key}`;
                    let b64 = await getImage(key_temp);
                    arr3[i].image = b64;
                }
            }
            console.log(arr3)
            setTeam(arr3)
            // const snapshot = await firestore.collection("team").get();
            // setTeam(
            //     snapshot.docs.map((doc) => {
            //         const data = doc.data();
            //         data["id"] = doc.id;
            //         return data;
            //     })
            // );
        };
        fetchData();
    }, []);

    return (
        <>
            <PlainHeader />

            <div className="privacy-head-div">
                <Container gap={3}>
                    <p className="privacy-heading-about-us">Grievance Redressal Policy</p>
                    <br />
                </Container>
            </div>

            <Container className="mt-4">
                <Row
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "800",
                    }}
                >
                    <Col
                        style={{ display: "flex", justifyContent: "flex-start" }}
                        md={12}
                        lg={12}
                        sm={12}
                        xs={12}
                    >
                        <p className='' style={{ fontWeight: "800" }}>
                            We aim to resolve your complaints and/or issues in a timely manner. You may go through the grievance redressal mechanism reproduced hereunder:
                        </p>
                    </Col>
                </Row>

                <br />

                <Row
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "800",
                    }}
                >
                    <Col
                        style={{ display: "flex", justifyContent: "flex-start" }}
                        md={12}
                        lg={12}
                        sm={12}
                        xs={12}
                    >
                        <p className='' style={{ fontWeight: "800" }}>
                            <span className="underline">LEVEL 1</span><br /><br />
                            In order to raise complaints and/or issues, you may e-mail the same at support.embifi.in and we will aim to address your concerns arising out of our services and/or products or acts relating to the use of the website within 10 business days.
                        </p>
                    </Col>
                </Row>

                <br />

                <Row
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "800",
                    }}
                >
                    <Col
                        style={{ display: "flex", justifyContent: "flex-start" }}
                        md={12}
                        lg={12}
                        sm={12}
                        xs={12}
                    >
                        <p className='' style={{ fontWeight: "800" }}>
                            <span className="underline">LEVEL 2</span><br /><br />
                            If you remain dissatisfied with our response you may contact the designated Grievance Officer of the Company at:
                            <br />
                            <br />
                            Name: Kanupriya Agarwal
                            <br />
                            Address: 603-604, 6th Floor, Thapar House, Building No. N161 Gulmohar Enclave Community Centre, Near Green park metro station New Delhi 110016
                            <br />
                            Phone: +91 9650327557
                            <br />
                            Email: kanupriya.agarwal@embifi.in
                            <br />
                            [10:00 AM to 5:00 PM, Monday to Friday, except Public Holidays]

                        </p>
                    </Col>
                </Row>

                <br />

                <Row
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "800",
                    }}
                >
                    <Col
                        style={{ display: "flex", justifyContent: "flex-start" }}
                        md={12}
                        lg={12}
                        sm={12}
                        xs={12}
                    >
                        <p className='' style={{ fontWeight: "800" }}>
                            <span className="underline">LEVEL 3</span><br /><br />
                            If your query or complaint has not been satisfactorily resolved at previous levels within 30 business days, you can reach out to the RBI ombudsman at https://cms.rbi.org.in/ or visit the Sachet portal at https://sachet.rbi.org.in/
                        </p>
                    </Col>
                </Row>

            </Container>

            <div style={{ marginTop: '10em' }} />

            <FooterComp />
        </>
    );
};