import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

export function LinkedInIcon() {
    return (

        <FontAwesomeIcon icon={faLinkedin}/>

    );
}

